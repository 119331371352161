import React from 'react'
import emailjs from 'emailjs-com';

import Layout from '../components/layout'
import Seo from "../components/seo"


class MeContacterPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      message: ''
    }
    this.handleChange = this.handleChange.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
  }

  handleChange(event) { 
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({[name]: value});  
  }

  sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }

  handleSubmit(event) {

    event.preventDefault();
    
    const templateParams = {
      from_name: this.state.name + " (" + this.state.email + ")",
      to_name: 'Matthieu',
      message: this.state.message
    };

    emailjs
      .send('service_qa5av2y', "template_nka367q", templateParams, "user_8DLa6KDzyRM7oRT2W3mai")
      .then(
        function(response) {
          alert("Message bien envoyé, merci ! Je vous recontacte très bientôt")
        },
        function(err) {
          alert("Le message n'a pas pu être envoyé. Contactez-moi par mail à 'matthieu.genicot@gmail.com'")
        }
      );this.setState({
      name: "",
      email: "",
      message: ""
    });
  }
  

  handleSubmit2(event) {    
    console.log('current state : ' + JSON.stringify(this.state));
    alert('current state : ' + JSON.stringify(this.state));
    event.preventDefault();
  }

  render() {
    return (
      <Layout>
      <Seo title="Me contacter" />
      <div id="main">
            <section>
              <form method="post" action="#" onSubmit={this.handleSubmit}>
                <div class="row gtr-uniform">
                  <div class="col-6 col-12-xsmall">
                    <label htmlFor="demo-name">Nom</label>
                    <input type="text" name="name" id="demo-name" placeholder="" value={this.state.name} onChange={this.handleChange}/>
                  </div>
                  <div class="col-6 col-12-xsmall">
                    <label htmlFor="demo-email">Email</label>
                    <input type="email" name="email" id="demo-email" placeholder="" value={this.state.email} onChange={this.handleChange}/>
                  </div>

                  <div class="col-12">
                    <label htmlFor="demo-message">Message</label>
                    <textarea name="message" id="demo-message" rows="6" 
                    placeholder="FR/EN&#10;Hi Matthieu,&#10;I would like to discuss with you about this super cool project I have."
                    value={this.state.message}
                    onChange={this.handleChange}>
                    </textarea>
                  </div>

                  <div class="col-12">
                    <ul class="actions">
                      <li><input type="submit" value="Envoyer message" class="primary" /></li>
                    </ul>
                  </div>
                </div>
              </form>
            </section>
            </div>
      </Layout>
    )
  }
}

export default MeContacterPage
